import React from "react";

type TProps = {
  className?: string;
  size?: number;
  bgColor?: string;
};

const BillardsClashLogo: React.FC<TProps> = ({
  className = "",
  size,
  bgColor,
}) => {
  return (
    <img
      className={className}
      src="/images/billards-clash-logo.png"
      alt="billards-clash-logo"
    />
  );
};

export default BillardsClashLogo;
