export const setClientIdEvent = "SetClientId"
export const setRegionEvent = "SetRegion"

// Game request
export const requestClientIdEvent = "RequestClientId"
export const requestOpenWagerEvent = "RequestOpenWager"
export const requestConnectWalletEvent = "RequestConnectWallet"
export const requestGameServerURL = "RequestGameServerURL"

// Template event to send back to game
export const focusPvPBattleEvent = "focusPvPBattle"
export const sendClientIdToGameEvent = "ProcessClientId"
export const sendWalletAddressToGame = "ProcessUserAddress"
export const sendGameServerURLToGame = "ProcessGameServerURL"
