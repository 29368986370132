import { useCallback, useEffect, useState } from "react";
import "./App.css";
import styles from "./app.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EnvTypes } from "myria-core-sdk";

// unity-react-webgl dependencies
import { Unity, useUnityContext } from "react-unity-webgl";
import React from "react";
// import { IWager } from "myria-game-pvp-sdk";
import { Modules, Types } from "myria-game-pvp-sdk";
import { useWalletConnect } from "./context/WalletConnectContext";
import { SUPPORT_SOUND, soundService } from "./utils/sound";
import {
  focusPvPBattleEvent,
  requestClientIdEvent,
  requestConnectWalletEvent,
  requestGameServerURL,
  requestOpenWagerEvent,
  sendClientIdToGameEvent,
  sendGameServerURLToGame,
  setClientIdEvent,
  setRegionEvent,
} from "./utils/utils";
import {
  metamaskNotInstallMessage,
  notificationTimeOut,
} from "./helpers/constants";
import {
  BillardsClashLogo,
  FullScreenIcon,
  MyriaLogo,
  TakeScreenShotIcon,
} from "./icons";

const sendNotification = async () => {
  soundService.playSound(SUPPORT_SOUND.NOTIFICATION_SOUND);

  const notification = new Notification(`Tap to Play`, {
    icon: `${window.origin + "/assets/images/myria-icon-log-black.png"}`,
    body: "Click to start playing game",
  });

  if (!("Notification" in window)) {
    alert("This browser does not support system notifications!");
  } else if (Notification.permission === "granted") {
    setTimeout(() => {
      notification.close();
    }, notificationTimeOut);
    notification.onclick = () => {
      window.focus();
    };
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission((permission) => {
      if (permission === "granted") {
        setTimeout(() => {
          notification.close();
        }, notificationTimeOut);
        notification.onclick = () => {
          window.focus();
        };
      }
    });
  }
};

function App() {
  const { onConnectWallet, isConnected, walletAddress, starkKey } =
    useWalletConnect();
  const [clientId, setClientId] = useState("");
  const [region, setRegion] = useState("Asia"); // Asia as region default
  const [wagerManager, setWagerManager] = useState<Modules.WagerManager>();
  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    sendMessage,
    addEventListener,
    removeEventListener,
    requestFullscreen,
    takeScreenshot,
    unload,
  } = useUnityContext({
    loaderUrl: "Build/public.loader.js",
    dataUrl: "Build/public.data",
    frameworkUrl: "Build/public.framework.js",
    codeUrl: "Build/public.wasm",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  useEffect(() => {
    //Access Permission Notification
    if (!("Notification" in window)) {
      alert("This browser does not support system notifications!");
    } else if (Notification.permission === "granted") {
      console.log('Notification permission have been granted already')
    } else {
      Notification.requestPermission()
        .then((permission: any) => {
          if (permission === "granted") {
            console.log('Notification is granted successfully');
          } else {
            toast.warning("You'll not receive notifications from game events");
          }
        })
        .catch(() => {
          toast.warning("You'll not receive notifications from game events");
        });
    }
  }, []);

  const alertUnityWithURL = useCallback(() => {
    const fullURL = window.location.href; // Gets the full URL
    // Assuming you have a GameObject in Unity called 'URLReceiver'
    // and a method 'ReceiveURL' that takes one string: the full URL.
    sendMessage("JSBridge", "ReceiveURL", fullURL);
  }, [sendMessage]);

  useEffect(() => {
    if (isLoaded) {
      alertUnityWithURL();
    }
  }, [isLoaded, alertUnityWithURL]); // Ensure this runs when Unity finishes loading

  const handlePassMetamaskInfo = (ethAddress: string, starkKey: string) => {
    sendMessage("JSBridge", "ProcessUserAddress", ethAddress);
  };

  const handleSendClientIdToGame = useCallback(
    (starkKeyParam?: string) => {
      let clientId = "";
      const currentStarkKey = starkKeyParam || starkKey;
      if (
        process.env.REACT_APP_PROJECT_ID &&
        starkKey &&
        currentStarkKey !== "0x"
      ) {
        clientId = `${process.env.REACT_APP_PROJECT_ID}#${currentStarkKey}`;
      }
      sendMessage("JSBridge", sendClientIdToGameEvent, clientId);
    },
    [sendMessage, starkKey]
  );

  const handleGameURLToGame = useCallback(() => {
    sendMessage("JSBridge", sendGameServerURLToGame, process.env.REACT_APP_GAME_SERVER_URL);
  }, [sendMessage]);

  useEffect(() => {
    let timeoutSendMessage: ReturnType<typeof setTimeout>;
    if (
      isConnected &&
      walletAddress.length > 2 &&
      starkKey.length > 2 &&
      isLoaded
    ) {
      // On here. Check message from unity game after loading game
      timeoutSendMessage = setTimeout(() => {
        handlePassMetamaskInfo(walletAddress, starkKey);
        handleSendClientIdToGame();
      }, 3000);
    }
    return () => {
      if (timeoutSendMessage) clearTimeout(timeoutSendMessage);
    };
  }, [isConnected, walletAddress, starkKey, isLoaded]);

  const handleRequestClientId = React.useCallback(() => {
    console.log("Game has requested the gameClientId");
    if (!process.env.REACT_APP_PROJECT_ID) {
      toast.warning(
        "Not ready to get game client ID - missing project ID in env"
      );
    }

    if (!starkKey || starkKey === "0x") {
      toast.warning("Not ready to get game client ID - stark key is not setup");
    }

    handleSendClientIdToGame();
    // toast.success('Send clientID successfully');
    console.log("Send client ID successfully");
  }, [handleSendClientIdToGame, starkKey]);

  const handleWalletConnect = React.useCallback(() => {
    const connectWallet = async () => {
      const wallet = await onConnectWallet();

      if (!wallet) {
        toast.warning(metamaskNotInstallMessage);
        return;
      }
      handlePassMetamaskInfo(wallet.ethAddress, wallet.starkKey);
      handleSendClientIdToGame(wallet.starkKey);
      console.log("Wallet -> ", wallet);
      if (wallet) {
        toast.success("Wallet is connected successfully.");
      } else {
        toast.warning("Wallet has failed connection. Please try again.");
      }
    };
    connectWallet();
  }, [handlePassMetamaskInfo]);

  const handleOpenWager = React.useCallback(() => {
    console.log("on Open Wager", walletAddress);
    if (!isConnected) {
      toast.warning(
        "Wallet connection is not ready, click on Wallet Connect before opening Wager!"
      );
      return;
    }
    if (!clientId) {
      toast.warning("Require client ID from game");
      return;
    }

    if (!process.env.REACT_APP_PROJECT_ID) {
      toast.warning("Require project ID from game");
      return;
    }

    const wagerParams: Types.IWager = {
      clientId: clientId, // Game client generated
      env: EnvTypes.STAGING,
      walletAddress: walletAddress,
      starkKey,
      projectId: process.env.REACT_APP_PROJECT_ID,
      region,
    };

    if (!process.env.REACT_APP_WAGER_URL) {
      toast.warning("Require PvP Battle URL to PvP SDK");
      return;
    }
    if (!wagerManager) {
      const wagerManagerModule = new Modules.WagerManager(
        wagerParams,
        process.env.REACT_APP_WAGER_URL,
        sendNotification
      );
      setWagerManager(wagerManagerModule);
      wagerManagerModule.openWager(false);
    } else {
      wagerManager.openWager(false);
    }
  }, [walletAddress, isConnected, starkKey, clientId, region, wagerManager]);

  useEffect(() => {
    const eventWrapper = (event: any) => {
      // Directly use `event` as a string since it's just a number
      const id = String(event); // Convert number to string if necessary
      setClientId(id);
    };

    addEventListener(setClientIdEvent, eventWrapper);
    return () => {
      removeEventListener(setClientIdEvent, eventWrapper);
    };
  }, [addEventListener, removeEventListener]);

  /**
   * Handle focus into game screen
   */
  useEffect(() => {
    const handleFocusPvPBattle = (event: any) => {
      wagerManager?.focusPvPBattle();
    };
    addEventListener(focusPvPBattleEvent, handleFocusPvPBattle);
    return () => {
      removeEventListener(focusPvPBattleEvent, handleFocusPvPBattle);
    };
  }, [addEventListener, removeEventListener, wagerManager]);

  useEffect(() => {
    const eventWrapper = (event: any) => {
      setRegion(String(event));
    };

    addEventListener(setRegionEvent, eventWrapper);
    return () => {
      removeEventListener(setRegionEvent, eventWrapper);
    };
  }, [addEventListener, removeEventListener]);

  /**
   * Handle the requestClientID event from Game
   */
  useEffect(() => {
    addEventListener(requestClientIdEvent, (e: any) => {
      handleRequestClientId();
    });
    return () => {
      removeEventListener(requestClientIdEvent, (e: any) =>
        handleRequestClientId()
      );
    };
  }, [handleRequestClientId, addEventListener, removeEventListener]);

  /**
   * Handle the requestGameServerURL event from Game
   */
    useEffect(() => {
      addEventListener(requestGameServerURL, (e: any) => {
        handleGameURLToGame();
      });
      return () => {
        removeEventListener(requestGameServerURL, (e: any) =>
          handleGameURLToGame()
        );
      };
    }, [handleGameURLToGame, addEventListener, removeEventListener]);

  /**
   * Handle the requestOpenWager event from Game
   */
  useEffect(() => {
    addEventListener(requestOpenWagerEvent, handleOpenWager);
    return () => {
      removeEventListener(requestOpenWagerEvent, handleOpenWager);
    };
  }, [handleOpenWager, addEventListener, removeEventListener]);

  /**
   * Handle the requestConnectWallet event from Game
   */
  useEffect(() => {
    addEventListener(requestConnectWalletEvent, (e: any) => {
      console.log(requestConnectWalletEvent);
      handleWalletConnect();
    });
    return () => {
      removeEventListener(requestConnectWalletEvent, (e: any) =>
        handleWalletConnect()
      );
    };
  }, [handleWalletConnect, addEventListener, removeEventListener]);

  const handleTakeScreenShot = () => {
    const screenImgBase64 = takeScreenshot();

    //can be convert to img
    if (screenImgBase64) {
      navigator.clipboard.writeText(screenImgBase64).then(
        function () {
          toast("Capture screenshot success!", { type: "success" });
        },
        function (err) {
          toast(err, { type: "error" });
        }
      );
    }
  };

  return (
    <div className={styles.container}>
      <ToastContainer hideProgressBar />
      <div className={styles.contentContainer}>
        <div className={styles.buttonWrapper}>
          <TakeScreenShotIcon
            className={styles.takeScreenShotButton}
            onClick={handleTakeScreenShot}
          />
          <FullScreenIcon
            className={styles.fullScreenButton}
            onClick={() => {
              requestFullscreen(true);
            }}
          />
        </div>
        <div className={styles.unityWrapper}>
          {isLoaded ? null : (
            <div className={styles.overlayContentWrapper}></div>
          )}
          <Unity
            unityProvider={unityProvider}
            style={{ display: isLoaded ? "block" : "none" }}
          />
          {!isLoaded ? (
            <>
              <BillardsClashLogo className={styles.gameLogo} />
              <p className={styles.powerText}>Power By</p>
              <MyriaLogo className={styles.namePowerLogo} />
              <section className={styles.loadingWrapper}>
                <div className={styles.loadingBarWrapper}>
                  <div className={styles.loadingBar}>
                    <div
                      className={styles.loadingBarFill}
                      style={{ width: loadingProgression * 100 + "%" }}
                    />
                  </div>
                  <p className={styles.numberLoading}>
                    {(loadingProgression * 100).toFixed(0) + "%"}
                  </p>
                </div>
              </section>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default App;
