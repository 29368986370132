export enum SUPPORT_SOUND {
    NOTIFICATION_SOUND = '/sounds/messageNotification.mp3',
  }
  
  class SoundService {
    private playing: { [id: string]: HTMLAudioElement };
    private mute: boolean;
  
    constructor() {
      this.mute = false;
      this.playing = {};
    }
  
    playSound(sound: SUPPORT_SOUND, { loop = false } = {}) {
      const audio = new Audio(sound);
      audio.id = Date.now().toString();
      audio.muted = this.mute;
      // audio.play();
      audio.loop = loop;
      audio.autoplay = true;
      audio.onended = () => {
        delete this.playing[audio.id];
      };
      this.playing[audio.id] = audio;
      return audio;
    }
  
    setMuted(isMuted: boolean) {
      this.mute = isMuted;
      Object.values(this.playing).forEach((audio) => (audio.muted = isMuted));
    }
  }
  
  export const soundService = new SoundService();
  